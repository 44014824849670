// Used in:
// admin/settings/settings/index.html
// admin/settings/settings/user_interface.html
export function initialize_color() {
  $('.color').spectrum({
    showAlpha: true,
    showInput: true,
    preferredFormat: 'rgb'
  });
};
